<template>

  <div class="bg-white">
    <div class="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:py-20 lg:px-8">
      <div class="lg:grid lg:grid-cols-3 lg:gap-8">
        <div>
          <h2 class="text-3xl font-extrabold text-gray-900">
            Veel gestelde vragen
          </h2>
          <p class="mt-4 text-lg text-gray-500">Kan je je antwoord niet vinden? Neem even contact op <a href="mailto:rutger.heijmerikx@mccoy-partners.com" class="font-medium text-orange-600 hover:text-orange-500">met Rutger</a>.</p>
        </div>
        <div class="mt-12 lg:mt-0 lg:col-span-2">
          <dl class="space-y-12">
            <div>
              <dt class="text-lg leading-6 font-medium text-gray-900">
                Hoe plaats/embed ik een video in een blog
              </dt>
              <dd class="mt-2 text-base text-gray-500">
                Een video vanuit Youtube kan je als volgt embedden. Ga op Youtube naar de video, kopieer de url van de video uit de adresbalk van je browser. (Zoals bijv: https://www.youtube.com/watch?v=T33e7uUzBzg) Vervolgens plak je deze url in de tekst waar je de video wil hebben en druk je op de 'enter' toets. De video wordt vervolgens automatisch geembed.
              </dd>
            </div>

            <div>
              <dt class="text-lg leading-6 font-medium text-gray-900">
                Hoe link ik naar een overzicht met updates met een specifieke tag.
              </dt>
              <dd class="mt-2 text-base text-gray-500">
                Link naar updates met 1 specifieke tag:
                <br/><br/>
                https://mccoy-partners.com/updates?tag=tag-1<br/>
                https://mccoy-partners.com/updates?tag=supply-chain-execution<br/>
                <br/><br/>
                Link naar updates met meerdere tags:
                <br/><br/>
                https://mccoy-partners.com/updates/tags/tag-1/tag-2/tag-3 (max 3 tags)<br/>
                https://mccoy-partners.com/updates/tags/supply-chain-execution/technology/s4hana/ (max 3 tags)<br/>
              </dd>
            </div>

            <div>
              <dt class="text-lg leading-6 font-medium text-gray-900">
                Waar vind ik mijn portretfoto's of die van mijn collega's?
              </dt>
              <dd class="mt-2 text-base text-gray-500">
                Link naar de foto's:
                <br/><br/>
                <a href="https://www.heijmerikx.com/b82cd40da5-client-access-settings/mccoy-portraits" target="_blank" class="text-orange-600">https://www.heijmerikx.com/b82cd40da5-client-access-settings/mccoy-portraits</a><br/>
              </dd>
            </div>

          </dl>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: 'Documentation'
};
</script>
